@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  cursor: none;
} */

#hero-bg {
  position: relative;
}
/* 
#hero-bg::before {
  content: "";
  background-image: url("./assets/img/hero-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  filter: brightness(0.8);
} */

.long-section {
  height: 100%;
  /* background: #0d1e37; */
  /* background-color: rgba(13, 30, 55, 0.7); */
}

.long-section::before {
  content: "";
  background: url("./assets/img/lion-head.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 15%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.long-section::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #0d1e37;
  z-index: -2;
}

.four-bar-lines {
  z-index: -1;
}

/* .long-section::after {
  background: black;
}

/* .left-statue {
  top: 40rem;
  left: 25rem;
} */

/* .features-slide-container > img {
  transition: transform 0.5s ease;
} */

.reserve-a-unit-btn {
  background: rgba(0, 0, 0, 0.29);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.8px);
  -webkit-backdrop-filter: blur(7.8px);
}

.sticky-button {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.29);
  /* border-radius: 16px; */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.8px);
  -webkit-backdrop-filter: blur(7.8px);
  border: 1px solid rgba(255, 255, 255, 0.44);
}

.glass-effect {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.29);
  /* border-radius: 16px; */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.8px);
  -webkit-backdrop-filter: blur(7.8px);
  z-index: -1;
  /* border: 1px solid rgba(255, 255, 255, 0.44); */
}

.slider-figcaption {
  position: absolute;
  bottom: 0;
  background-color: #000;
  padding: 1rem 2rem;
  /* line-height: 29px; */
  /* border-radius: 0.5rem 0.5rem 0 0;
  padding: 1rem 2rem;
  transform: translate3d(-50%, 100%, 0);
  opacity: 0;
  transition: all 0.3s ease-in-out; */
}

.slider-figcaption-active {
  transform: translate3d(-50%, 0, 0);
  opacity: 1;
}

.uniquekeyfacts {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "first"
    "second"
    "third";
}

.uniquekeyfacts > * {
  margin-left: 10rem;
}

.first {
  grid-area: first;
  /* justify-self: flex-start; */
}
@media screen and (max-width: 768px) {
  .first {
    display: flex;
    margin-left: 2px;
    /* background-color: purple; */
  }
}

.second {
  grid-area: second;
  /* justify-self: center; */
}

@media screen and (max-width: 768px) {
  .second {
    display: flex;
    margin-top: 2rem;
    margin-left: 7rem;
    /* justify-self: center; */
  }
}

.third {
  grid-area: third;
  /* justify-self: flex-end; */
}

@media screen and (max-width: 768px) {
  .third {
    display: flex;
    margin-top: 2rem;

    /* justify-self: center; */
    /* margin-right: 5rem; */
  }
}

@media screen and (max-width: 768px) {
  .forty {
    display: flex;
    margin-left: 1.7rem;
    /* background-color: purple; */
  }
}

.icons-section__container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(12%, 100%), 1fr));
  row-gap: 8rem;
  column-gap: 8rem;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .uniquekeyfacts {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 320px) and (max-width: 420px) {
  .icons-section__container {
    grid-template-columns: repeat(auto-fill, minmax(min(25%, 100%), 1fr));
    padding: 2rem 3rem;
  }
}

@media screen and (min-width: 421px) and (max-width: 825px) {
  .icons-section__container {
    grid-template-columns: repeat(auto-fill, minmax(min(5%, 100%), 1fr));
    padding: 2rem 3rem;
  }
}

@layer components {
  .form-control {
    @apply py-1 px-0 bg-transparent border-b-[1px] font-inter border-[#FF7A00] outline-none text-[#4D4D4D] placeholder:text-[#4D4D4D];
  }
}

.form-control:focus-within {
  color: #fff;
}

.footer-about-sujimoto {
  position: relative;
  z-index: 20;
}

.footer-about-sujimoto::after {
  content: "";
  background: url("./assets/img/footer-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 80%;
  height: 100%;
  width: 100%;
  z-index: -10;
}

.footer-about-sujimoto::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    360deg,
    #000000 16.01%,
    rgba(123, 123, 123, 0.36) 122.36%
  );
  background-blend-mode: multiply;
  z-index: 1;
}

.reserve-hotspot .reserve-left-img {
  /* animation: 1s slideLeftImg; */
  /* transform: translateX(60%); */
}

.center-img-wrapper {
  border-radius: 20px;
  /* background: #000; */
}

.center-img-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background: url("./assets/img/hero-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
}

.center-img-wrapper-after {
  /* content: ""; */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  background: linear-gradient(
    360deg,
    #000000 16.01%,
    rgba(123, 123, 123, 0.36) 120.36%
  );
  z-index: 10;
}

.pulser {
  aspect-ratio: 1;
  border-radius: 50%;
  animation: pulse 2s infinite;
}

.pulser-circle {
  aspect-ratio: 1;
  border-radius: 50%;
  animation: pulseWhite 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: #ff7a00 0 0 0 0;
  }

  75% {
    box-shadow: #ff7a0000 0 0 0 16px;
  }
}

@keyframes pulseWhite {
  0% {
    box-shadow: #ffffff 0 0 0 0;
  }

  75% {
    box-shadow: #ffffff00 0 0 0 16px;
  }
}

.hovered-feature {
  opacity: 0;
  transform: translateY(-5px);
  transition: all 0.75s ease-in-out;
}

.hovered-parent {
  position: relative;
  z-index: 10;
}

.hovered-parent::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, #192847 5.26%, rgba(217, 217, 217, 0) 18%);
  mix-blend-mode: multiply;
  z-index: 1;
  pointer-events: none;
}

.hovered-parent:hover + .hovered-feature {
  opacity: 1;
  transform: translateY(0);
}

.feature-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.feature-img:hover {
  transform: scale(1.1);
}

.more-content-container {
  background: rgba(255, 255, 255, 0.29);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.swiper-custom-nav {
  z-index: 50;
}

.swiper-custom-nav > * {
  opacity: 0.6;
  transition: all 0.3s ease-in-out;
}

.swiper-custom-nav > *:hover {
  opacity: 1;
}

.ball {
  width: 40px;
  height: 40px;
  position: fixed;
  top: 0;
  left: 0;
  border: 3px solid #ff7a00;
  border-radius: 50%;
  pointer-events: none;
  z-index: 100;
}
